.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.App-header {
  background-color: #141414;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  transition: background-color 0.75s;
}

* {
  margin-top: 0;
}
