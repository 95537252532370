.cutesy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cutesy-list {
  background-color: #f8fbfe;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 16px;
  z-index: 10;
  position: relative;
  text-align: left;
  font-family: 'Nanum Pen Script', cursive;

  @media (min-width: 768px) {
    width: 50%;
    height: 80%;
  }
  width: 90%;
  height: 92%;
  color: #141414;
  padding: 16px;
  overflow-y: auto;

  h1 {
    margin-bottom: 16px;
  }

  h2 {
    margin-bottom: 8px;
    font-size: 1.75rem;
  }

  img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  p {
    font-size: 1.35rem;
    font-family: 'Comic Neue', cursive;
  }
}

.promise {
  margin-bottom: 48px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  button {
    margin: 0 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    background-color: #141414;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    width: 25%;
    &.yes {
      background-color: #5cb85c;
      color: #ffffff;
    }
    &.no {
      background-color: #ed4337;
      color: #ffffff;
    }
  }
}

.cutesy-divider {
  margin: 32px;
}
.cutesy-eyes {
  position: relative;
  width: 100%;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cutesy-hearts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  overflow-x: hidden;
  overflow-y: hidden;
  pointer-events: none;
}

.cutesy-heart {
  display: inline-block;
}
