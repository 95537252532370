.password {
  font-family: 'Roboto Mono', monospace;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

.password-input {
  font-family: 'Roboto Mono', monospace;
  padding: 2px 12px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  width: 300px;
  font-size: 32px;
}

.password-submit {
  margin-top: 16px;
  font-family: 'Roboto Mono', monospace;
  padding: 2px 12px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 24px;
}

.password-hint {
  font-size: 16px;
  margin-top: 16px;
}
