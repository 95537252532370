.yippie {
  padding: 16px;

  img {
    width: 100%;
  }
}

.yippie-text {
  color: #141414;
  font-size: 2rem;
}
